
.addon-card__orders
  @apply py-24
  &:not(:last-of-type)
    @apply border-b-1 border-grey-lighter
  &__brief__cover
    @apply flex-no-shrink bg-center bg-cover mb-16
    height: 130px
    width: 100%
    @screen md
      @apply mb-0
      height: 96px
      width: 138px
  &__plan
    @apply pt-4
.collapseBlock
  h6
    @apply cursor-pointer
  &.open
    .collapseBlock__body
      max-height: 100%
    .owl-caret-right
      @apply inline-block
      transform: rotate(90deg)
  &__body
    max-height: 4.5rem
    @apply overflow-hidden relative
    &__mask
      @apply block w-full absolute cursor-pointer
      bottom: 0
      content: ''
      height: 4.5rem
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
